import React from "react";

function Bio() {
    return (
        <div id="sb">
            <p>
                Hi, I’m Amber! I’m a software engineer working on web at <a target="_blank" rel="noopener noreferrer" href="https://www.dronedeploy.com/">DroneDeploy</a>.
            </p>
        </div>
    )
}

export default Bio;

